@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato';
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

/* .navbar {
  background-color: #d23c77;
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 50px;
}

.navbar a {
  color: #ffffff;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
}

.navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
} */

.buttons-container {
  display: flex;
}

.arrow-button {
  background-color: #d23c77;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  margin: 5px;
}

.arrow-button:hover {
  background-color: #a32a58;
}

.arrow-button.dark-mode {
  background-color: #b04164;
}

.header-section-a {
  position: relative;
  text-align: center;
  color: white;
  background-image: url("https://cdn.glitch.global/22b02521-8d9f-48fd-bd65-d74f091c9a80/home.image.png?v=1720680014556");
  background-size: cover;
  background-position: center;
  height: 500px;
}

.header-section-a.white-background {
  background-image: none;
  background-color: #ffffff;
}

.header-section-a.dark-mode {
  background-color: #222222;
  background-image: none;
}

.header-section-a img {
  width: 100%;
  height: auto;
  display: none;
  /* Hide the image element */
}

.container h2 {
  margin-top: 90px;
  font-weight: bold;
  text-align: left;
  font-size: 1.3em;
}

.team-member h2 {
  margin-top: 30px;
}

.search-section {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 700px;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-section.dark-mode {
  background-color: #333333;
}

.search-section h1 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #000000;
  font-family: Georgia;
  font-weight: bold;
}

.search-section p {
  color: #000000;
  font-weight: 600;
  font-family: 'Lato';
}

.search-section h1.dark-mode {
  color: #ffffff;
}


.search-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.search-box input,
.search-box select {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
  width: 400px;
}

.search-box input.dark-mode,
.search-box select.dark-mode {
  background-color: #555555;
  border: 1px solid #777777;
  color: #ffffff;
}

.search-box input::placeholder,
.search-box select::placeholder {
  color: #000000;
}

.search-box input.dark-mode::placeholder,
.search-box select.dark-mode::placeholder {
  color: #dddddd;
}

.search-box input::placeholder,
.search-box select::placeholder {
  color:#cccccc; 
}

.search-box button {
  padding: 10px 20px;
  border: none;
  background-color: #d23c77;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.search-box button:hover {
  background-color: #a32a58;
}

.search-box button.dark-mode {
  background-color: #b04164;
}

#searchErrorMessage {
  margin-top: 10px;
  color: red;
}

.doctorbox {
  text-align: left;
}

.container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.container.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.infographic-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.infographic-image {
  width: 100%;
  height: auto;
}


.infographic-item {
  margin: 20px;
  max-width: 300px;
  color: #000000;
}

.infographic-item.dark-mode {
  background-color: #222222;
  color: #ffffff;
}

.featured-sources-wrapper {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.featured-sources {
  display: flex;
}

.source-card {
  flex: 0 0 25%;
  box-sizing: border-box;
  padding: 10px;
}

.source-card.dark-mode {
  background-color: #333333;
  color: #ffffff;
}

.source-card a h3 {
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-top: 10px;
}

.source-card a h3.dark-mode {
  color: #ffffff;
}

.source-card a {
  text-decoration: none;
}

.article-image {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
}

/* About us */
.about-section {
  text-align: left;
}

.about-section.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.about-section h1 {
  font-size: 2em;
  margin-bottom: 10px;
  font-weight: 600;
}

.about-section h1.dark-mode {
  color: #ffffff;
}

.about-section p {
  font-size: 1em;
  margin-bottom: 20px;
}

.about-section p.dark-mode {
  color: #dddddd;
}

.team-member {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.member-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 200px;
  flex: 1 1 calc(33.333% - 40px);
}

.member-item.dark-mode {
  background-color: #333333;
  color: #ffffff;
}

.member-photo {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  /* margin-bottom: 10px; */
  object-fit: cover;
}

.member-item h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.member-item p {
  font-size: 0.9em;
  color: #666666;
}

.member-item p.dark-mode {
  color: #bbbbbb;
}

.about-section p {
  font-size: 0.9em;
  color: #666666;
}

.member-item h3 {
  font-size: 1em;
  color: #000000;
  margin-bottom: 10px;
}

.member-item h3.dark-mode {
  color: #ffffff;
}

.filter-container {
  display: flex;
  margin-bottom: 20px;
  margin-left: auto;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 0 15px;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 20px;
  background-color: #d23c77;
  border-radius: 50px;
  font-family: inherit;
  cursor: pointer;
}

.dropdown .dropbtn.dark-mode {
  background-color: #b04164;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content.dark-mode {
  background-color: #333333;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a.dark-mode {
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content a:hover.dark-mode {
  background-color: #444444;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: #a32a58;
  color: black;
}

.doctor-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  color: #000000;
  display: flex;
}

.doctor-info {
  width: 66%;
  margin-right: 2%;
}

.search-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  margin-right: 2%;
  text-align: right;
}

.doctor-card.dark-mode {
  background-color: #333333;
  border-color: #555555;
  color: #ffffff;
}

/* Ensure this provides spacing below the navbar */
.main-content {
  margin-top: 20px;
  /* Adjust as needed to space out content from the Navbar */
}

.fluid-container {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  height: 800px;
  row-gap: 8px;
}

.map-container {
  flex: 2;
}

.cards-container {
  flex: 1;
  padding: 20px;
  padding-top: 0px;
  overflow-y: scroll;
}

#noDoctorsError {
  padding-top: 10px;
}

#cards-title {
  background-color: #d23c77;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
}

.small-text {
  color: #de598e;
}

.map-feature {
  height: 100%;
  width: 100%;
}

.map-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-right: 30px;
  padding-left: 30px;
}

.filter-search {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.map-filter input,
.map-filter select {
  width: 175px;
  text-align: left; 
}

.filter-search input,
.filter-search select,
.filter-search button {
  margin-right: 1%;
}

.sort-search {
  text-align: right;
}

.active-button {
  font-family: 'Lato', sans-serif;
}

.special-container {
  text-align: left;
}

#learnMoreButton {
  border-radius: 50px;
  border-style: solid;
  border-color: #d23c77;
  color: #d23c77;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

/* more info  */
.more-info-section {
  padding: 50px 20px;
}

.more-info-section h2 {
  margin-bottom: 30px;
  padding-bottom: 20px;
  font-size: 2em;
  color: #000000;
  max-width: 1200px;
  margin: 0 auto;
}

.more-info-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.more-info-card {
  flex: 0 0 calc(33.333% - 10px);
}

.more-info-image {
  width: 100%;
  height: auto;
  display: block;
}

.info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.info-text {
  max-width: 40%;
}

.info-text h2 {
  font-size: 2em;
  color: #000000;
  margin-bottom: 20px;
}

r .info-text p {
  font-size: 1.2em;
  color: #333333;
  margin-bottom: 30px;
}

.find-doctor-button {
  padding: 10px 20px;
  background-color: #d23c77;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.find-doctor-button:hover {
  background-color: #a32a58;
}

.info-image {
  position: relative;
  width: 50%;
}

.image-wrapper {
  position: relative;
  width: 100%;
}

.image1,
.image2 {
  width: 70%;
  height: auto;
  border-radius: 8px;
  position: absolute;
}

.image1 {
  top: -170px;
  left: 0;
  z-index: 2;
}

.image2 {
  top: -80px;
  left: 100px;
  z-index: 1;
}

/* Kate section */
.find-gynecologist-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
}

.image-side,
.text-side {
  flex: 1 1 50%; /* Ensure equal width for both sides */
  justify-content: center;
  align-items: center;
}

.gyno-image {
  width: 85%;
  height: auto;
  padding: 30px;
  border-radius: 30px;
  position: relative;
  left: 20px;
}

.text-side {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  left: -40px;
}

.text-side h2 {
  position: relative;
  z-index: 2;
  color: white;
  padding: 10px;
  padding-left: 0px;
  padding-bottom: 80px;
  top: 15px;
}

.rectangle-bg {
  position: absolute;
  top: 0;
  left: -85px;
  height: 140px;
  width: 110%;
  background-color: #d23c77;
  z-index: 1;
}

.text-side p {
  font-size: 18px;
  margin-bottom: 20px;
}

.options-list {
  list-style-type: none;
  padding-left: 0;
}

.icon-text-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.text-container {
  display: flex;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.find-doctor-button {
  background-color: #d23c77;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.find-doctor-button:hover {
  background-color: #b04164;
}

/* dark mode for section above */
.dark-mode .main-content {
  background-color: #1e1e1e;
  color: #f1f1f1;
}

.dark-mode .fluid-container {
  background-color: #2e2e2e;
  color: #f1f1f1;
}

.dark-mode .map-container,
.dark-mode .cards-container {
  background-color: #2e2e2e;
  color: #f1f1f1;
}

.dark-mode #cards-title {
  background-color: #b03a6b;
  color: #f1f1f1;
}

.dark-mode .small-text {
  color: #f5a5b8;
}

.dark-mode .more-info-section {
  background-color: #2e2e2e;
  color: #f1f1f1;
}

.dark-mode .more-info-cards {
  background-color: #2e2e2e;
}

.dark-mode .more-info-card {
  background-color: #3e3e3e;
  color: #f1f1f1;
}

.dark-mode .more-info-image {
  border: 2px solid #f1f1f1;
}

.dark-mode .info-section {
  background-color: #2e2e2e;
  color: #f1f1f1;
}

.dark-mode .info-text h2 {
  color: #f1f1f1;
}

.dark-mode .info-text p {
  color: #d1d1d1;
}

.dark-mode .find-doctor-button {
  background-color: #b03a6b;
  color: #f1f1f1;
}

.dark-mode .find-doctor-button:hover {
  background-color: #a02d58;
}

/* mobile screens */
@media (max-width: 768px) {
  .search-section {
    width: 90%;
    max-width: 500px;
    padding: 15px;
  }

  .search-box {
    flex-direction: column;
    gap: 5px;
  }

  .search-box input,
  .search-box select,
  .search-box button {
    width: 100%;
  }

  .container {
    width: 95%;
    padding: 10px;
  }

  .infographic-section {
    flex-direction: column;
    gap: 20px;
  }

  .infographic-item {
    max-width: 100%;
  }

  .team-member {
    flex-direction: column;
    align-items: center;
  }

  .source-card {
    flex: 0 0 50%;
  }

  .member-item {
    width: 100%;
    text-align: center;
  }

  .member-item h2 {
    text-align: center;
  }

  .about-section h1 {
    padding-top: 30px;
    text-align: center;
  }

  .about-section p {
    text-align: center;
  }

  .fluid-container {
    flex-direction: column;
    overflow-y: visible;
    height: auto;
  }

  .cards-container {
    overflow-y: visible;
  }

  .map-container {
    height: 300px;
  }

  .info-section {
    flex-direction: column;
    text-align: center;
  }

  .info-text,
  .info-image {
    width: 100%;
    max-width: 100%;
  }

  .info-text h2 {
    font-size: 1.5em;
  }

  .info-text p {
    font-size: 1em;
  }

  .info-image {
    margin-top: 20px;
  }

  .image-wrapper {
    position: relative;
    width: 50%;
    height: auto;
    padding-bottom: 130px;
  }

  .image1,
  .image2 {
    width: 70%;
    height: auto;
    border-radius: 8px;
    position: absolute;
  }

  .image1 {
    padding-top: 50px;
    top: -40px;
    left: 50%;
    z-index: 2;
  }

  .image2 {
    top: 70px;
    left: 75%;
    z-index: 1;
  }

  .more-info-cards {
    flex-direction: column;
    align-items: center;
  }

  .more-info-card {
    flex: 0 0 100%;
  }

  .more-info-section {
    text-align: center;
  }

  .dropdown {
    width: 100%;
    text-align: center;
  }

  .dropdown .dropbtn {
    width: 100%;
    padding: 10px;
  }

  .dropdown-content {
    min-width: 100%;
  }

  /* dark mode app */
  .App {
    background-color: white;
    color: black;
  }

  .App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }

  .App.dark-mode {
    background-color: black;
    color: white;
  }

  .App-header.dark-mode {
    background-color: #343a40;
  }
  
  .icon-text-container {
    display: flex; /* Ensures that the img and span are aligned horizontally */
    align-items: center; /* Vertically centers the content */
  }
  
  .icon {
    width: 20px; /* Set width as needed */
    height: 20px; /* Set height as needed */
    margin-right: 10px; /* Adds space between the img and span */
  }
  
  .icon-text-container span {
    margin-left: 10px; /* Adds space on the left of the text, if needed */
  } 

}  

