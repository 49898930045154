@import url('https://fonts.googleapis.com/css2?family=Playball&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

/* Navbar.css */
.navbar-container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
  }
  
  .navbar {
    background-color: #d23c77;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 50px;
  }
  
  .navbar a {
    color: #ffffff;
    margin: 0 15px;
    text-decoration: none;
    font-weight: 500;
  }
  
  .navbar-logo {
    font-size: 1.5em;
    font-weight: bold;
    font-family: 'Playball';
  }
  
  .navbar a:hover {
    border-radius: 30px;
    background-color: #de598e;
    color: #ffffff;
  }
  
  .navbar-toggle {
    display: none; 
  }

  @media (max-width: 768px) {
    .navbar {
      padding: 10px;
    }
  
    .navbar-logo {
      font-size: 1.2em;
    }

    .navbar-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 100%;
      background-color: #d23c77;
      border-radius: 10px;
      padding: 10px;
  }

  .navbar-menu a {
      padding: 10px 20px;
      margin: 0;
      text-align: center;
  }

  .navbar-menu.show {
      display: flex;
  }

  .navbar-toggle {
      display: block;
      background-color: transparent;
      border: none;
      font-size: .6em;
      color: white;
      cursor: pointer;
  }
}
  